<template>
    <div class="w-100">
        <select
            v-if="userCan.switch_terminal && store.terminal"
            :value="terminalValue"
            class="form-select border"
            @change="changeTerminal"
        >
            <option v-for="terminal in store.terminals" :key="terminal.ST_FLAG" :value="terminal.ST_FLAG">
                {{ terminal.PLNY_NAZEV }}
            </option>
        </select>

        <div v-else-if="store.terminal" class="ms-4 p-1">{{ store.terminal.PLNY_NAZEV }}</div>
    </div>
</template>

<script setup lang="ts">
import type { ITerminal } from "~/types/terminal";

const userCan = useCan();

const store = useStore();

const terminalValue = computed(() => {
    return store.terminal ? store.terminal.ST_FLAG : null;
});

function changeTerminal(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (store && store.terminals && Array.isArray(store.terminals) && store.terminals.length > 0) {
        const finding = store.terminals.find((terminal: ITerminal) => terminal.ST_FLAG === value);
        if (finding) {
            store.setActiveTerminal(finding);
        }
    }
}
</script>
