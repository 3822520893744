<template>
    <div>
        <div class="container">
            <div class="me-1">
                <div
                    class="nav-item border rounded-2"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    aria-controls="navbarItems"
                >
                    <button
                        type="button"
                        class="btn"
                        :title="$t('otManager.unprocessedCMR.count')"
                        data-bs-toggle="modal"
                        data-bs-toggles="tooltip"
                        data-bs-target="#secondJobsExportModal"
                    >
                        <font-awesome-icon icon="file-export" />Ex:
                        {{ unprocessedCMR.filter((job) => job.IE === "E").length }}
                    </button>
                </div>
            </div>
            <div>
                <div
                    class="nav-item border rounded-2"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    aria-controls="navbarItems"
                >
                    <button
                        type="button"
                        class="btn border rounded-2"
                        :title="$t('otManager.unprocessedCMR.count')"
                        data-bs-toggle="modal"
                        data-bs-toggles="tooltip"
                        data-bs-target="#secondJobsImportModal"
                    >
                        <font-awesome-icon icon="file-import" />Im:
                        {{ unprocessedCMR.filter((job) => job.IE === "I").length }}
                    </button>
                </div>
            </div>
        </div>

        <CommonModalComponent id="secondJobsExportModal" title="Export" :custom-footer="true" size="xl">
            <table class="table">
                <tbody>
                    <tr>
                        <th>{{ $t("otManager.secondJobs.container") }}</th>
                        <th>{{ $t("otManager.secondJobs.size") }}</th>
                        <th>{{ $t("otManager.secondJobs.owner") }}</th>
                        <th>{{ $t("otManager.secondJobs.position") }}</th>
                        <th>{{ $t("otManager.secondJobs.destination") }}</th>
                        <th>CMR</th>
                        <th>{{ $t("otManager.secondJobs.time") }}</th>
                    </tr>

                    <tr v-for="job in unprocessedCMR.filter((job) => job.IE === 'E')" :key="job.CONTAINER">
                        <td>{{ job.CONTAINER }}</td>
                        <td>{{ job.TYP }}</td>
                        <td>{{ job.OWNER }}</td>
                        <td>{{ job.POLOHA }}</td>
                        <td>{{ job.ROW_1 }}<br />{{ job.ROW_2 }}<br />{{ job.ROW_3 }}</td>
                        <td>{{ job.T_NL }}</td>
                        <td>{{ $dayjs(job.PP_DATE).format("YYYY-MM-DD") }} {{ job.PP_TIME }}</td>
                    </tr>
                </tbody>
            </table>
        </CommonModalComponent>

        <CommonModalComponent id="secondJobsImportModal" title="Import" :custom-footer="true" size="xl">
            <table class="table">
                <tbody>
                    <tr>
                        <th>{{ $t("otManager.secondJobs.container") }}</th>
                        <th>{{ $t("otManager.secondJobs.size") }}</th>
                        <th>{{ $t("otManager.secondJobs.owner") }}</th>
                        <th>{{ $t("otManager.secondJobs.position") }}</th>
                        <th>{{ $t("otManager.secondJobs.destination") }}</th>
                        <th>CMR</th>
                        <th>{{ $t("otManager.secondJobs.time") }}</th>
                    </tr>

                    <tr v-for="job in unprocessedCMR.filter((job) => job.IE === 'I')" :key="job.CONTAINER">
                        <td>{{ job.CONTAINER }}</td>
                        <td>{{ job.TYP }}</td>
                        <td>{{ job.OWNER }}</td>
                        <td>{{ job.POLOHA }}</td>
                        <td>{{ job.ROW_1 }}<br />{{ job.ROW_2 }}<br />{{ job.ROW_3 }}</td>
                        <td>{{ job.T_NL }}</td>
                        <td>{{ $dayjs(job.PP_DATE).format("YYYY-MM-DD") }} {{ job.PP_TIME }}</td>
                    </tr>
                </tbody>
            </table>
        </CommonModalComponent>
    </div>
</template>

<script setup lang="ts">
type Props = {
    terminalId: string;
};

const props = defineProps<Props>();
const unprocessedCMR = ref<Array<any>>([]);
const interval = ref<any>(null);
const axios = useAxios();
watch(
    () => props.terminalId,
    () => {
        loadSecondJobs();
    }
);

onMounted(() => {
    loadSecondJobs();
});

onUnmounted(() => {
    clearInterval(interval.value);
});

function loadSecondJobs() {
    axios.get(`api/terminals/${props.terminalId}/cmr/unprocessed/statistics`).then((result) => {
        unprocessedCMR.value = result.data;
    });
}
</script>

<style scoped>
.container {
    display: flex;
}
.container > div {
    flex: 1; /*grow*/
    width: 110px;
}
</style>
