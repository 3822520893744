<template>
    <CommonModalComponent
        id="locationsModal"
        ref="locationsModal"
        :title="$t('dispo.tripsHistory')"
        size="lg"
        :no-footer="true"
    >
        <div>
            <div class="form-control-group">
                <label>{{ $t("label.searchPlaceholder") }}</label>
                <input
                    v-model="inputSearch"
                    type="test"
                    class="form-control"
                    Placeholder=""
                    :class="loading ? 'disabled' : ''"
                />
            </div>
            <button
                :disabled="!inputSearch || inputSearch.length < 3"
                class="btn btn-primary btn-sm"
                :class="loading ? 'disabled' : ''"
                @click="SearchLocationsCMR"
            >
                {{ $t("label.search") }}
            </button>
            <div v-if="!loadedTrips.length && lastLoadedIndex" class="pt-3">
                <p class="text-danger">{{ $t("errors.containers.5") }}</p>
            </div>
            <CommonSpinnerComponent v-if="loading && !loadedTrips.length" class="pt-4" />
        </div>

        <div v-if="loadedTrips.length > 0">
            <hr />
            <div class="modal-70-scroll mt-4">
                <div id="tripsAccordion" class="accordion">
                    <div v-for="(trip, index) in loadedTrips" :key="trip.tripDetail.idTrip" class="card">
                        <div :id="'tripHeading' + index" class="card-header">
                            <h2 class="mb-0">
                                <button
                                    class="btn btn-block collapsed text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#tripCollapse' + index"
                                    aria-expanded="false"
                                    :aria-controls="'tripCollapse' + index"
                                    @click="toggleAccordion(index)"
                                >
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            {{ trip.tripDetail.container }} - {{ trip.tripDetail.orderNo }}
                                            {{ trip.tripDetail.cmr ? ` - ${trip.tripDetail.cmr}` : "" }}
                                        </div>
                                        <div class="text-end">
                                            <font-awesome-icon
                                                :icon="`chevron-${accordionActive === index ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </div>
                                </button>
                            </h2>
                        </div>
                        <div
                            :id="'tripCollapse' + index"
                            class="collapse"
                            :aria-labelledby="'tripHeading' + index"
                            data-bs-parent="#tripsAccordion"
                        >
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-header">
                                        {{ $t("cmr.containers") }}
                                    </div>

                                    <ul class="list-group list-group-flush bg-light">
                                        <li class="list-group-item">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-sm-12">ID:</div>
                                                    <div class="col-sm-12">{{ trip.tripDetail.idTrip }}</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        {{ $t("cmr.label.driver") }}
                                                    </div>
                                                    <div class="col-sm-12">{{ trip.tripDetail.driver }}</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        {{ $t("cmr.label.orderNo") }}
                                                    </div>
                                                    <div class="col-sm-12">{{ trip.tripDetail.orderNo }}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="trip.slots.length" class="card mt-3">
                                    <div class="card-header">
                                        {{ $t("label.slots") }}
                                    </div>

                                    <ul class="list-group list-group-flush">
                                        <li
                                            v-for="(slot, slotIndex) in trip.slots"
                                            :key="slotIndex"
                                            class="list-group-item"
                                        >
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-sm-12">{{ $t(`cmr.slot.${slot.direction}`) }}</div>
                                                    <div class="col-sm-12">{{ slot.from }} - {{ slot.to }}</div>
                                                    <div class="col-sm-12">{{ slot.gateCode }}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="trip.pickupDropoff.length" class="card mt-3">
                                    <div class="card-header">
                                        <div class="container">
                                            <div class="row">
                                                <div class="align-self-center col-auto me-auto">
                                                    {{ $t("label.delivery") }}
                                                </div>

                                                <div
                                                    v-if="
                                                        trip.pickupDropoff.length === 2 &&
                                                        trip.locations.length &&
                                                        trip.locations.filter(
                                                            (predicate) =>
                                                                new Date(predicate.time) >
                                                                    new Date(trip.pickupDropoff[0].date) &&
                                                                new Date(predicate.time) <
                                                                    new Date(trip.pickupDropoff[1].date)
                                                        ).length
                                                    "
                                                    class="col-auto"
                                                >
                                                    <button
                                                        class="btn-success mb-2 mt-2 pb-1 pe-2 ps-2 pt-1"
                                                        @click.prevent="
                                                            focusTrip(
                                                                trip.locations.filter(
                                                                    (predicate) =>
                                                                        new Date(predicate.time) >
                                                                            new Date(trip.pickupDropoff[0].date) &&
                                                                        new Date(predicate.time) <
                                                                            new Date(trip.pickupDropoff[1].date)
                                                                ),
                                                                trip.tripDetail
                                                            )
                                                        "
                                                    >
                                                        <font-awesome-icon icon="globe-europe" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ul class="list-group list-group-flush">
                                        <li
                                            v-for="(delivery, deliveryIndex) in trip.pickupDropoff"
                                            :key="deliveryIndex"
                                            class="list-group-item"
                                        >
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        {{ $t(`cmr.delivery.${delivery.type}`) }}
                                                    </div>
                                                    <div class="col-sm-12">{{ delivery.date }}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="trip.locations.length" class="card mt-3">
                                    <div class="card-header">
                                        <div class="container">
                                            <div class="row">
                                                <div class="align-self-center col-auto me-auto">
                                                    {{ $t("label.locations") }}
                                                </div>
                                                <div class="col-auto">
                                                    <button
                                                        class="btn-success mb-2 mt-2 pb-1 pe-2 ps-2 pt-1"
                                                        @click.prevent="focusTrip(trip.locations, trip.tripDetail)"
                                                    >
                                                        <font-awesome-icon icon="globe-europe" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li
                                            v-for="location in trip.locations"
                                            :key="location.id"
                                            class="list-group-item"
                                        >
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-sm-12">{{ location.time }}</div>
                                                    <div class="col-sm-12">
                                                        {{ location.lat.toFixed(6) }}, {{ location.lng.toFixed(6) }}
                                                    </div>
                                                    <div class="col-sm-12">ACC: {{ location.acc.toFixed(3) }}</div>
                                                    <div class="col-md-1">
                                                        <button
                                                            class="btn-success mb-2 mt-2 pb-1 pe-2 ps-2 pt-1"
                                                            @click.prevent="focusLocation(location, trip)"
                                                        >
                                                            <font-awesome-icon icon="paper-plane" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CommonSpinnerComponent v-if="loading && loadedTrips.length" />
                <button v-if="lastRecordsCount === maxRecords" class="btn btn-success btn-sm" @click="getData()">
                    {{ $t("label.loadMore") }}
                </button>
            </div>
        </div>
    </CommonModalComponent>
</template>

<script setup lang="ts">
import type { ITripHistoryLocation, ITripHistory, ITripHistoryDetail } from "~/../backend/types/tripHistory";
import type ModalComponent from "~/components/common/ModalComponent.vue";

const axios = useAxios();
const ridesStore = useRidesStore();
const loadedTrips = ref<Array<ITripHistory>>([]);
const accordionActive = ref<number>(-1);
const inputSearch = ref("");
const maxRecords = ref(30);
const lastRecordsCount = ref(0);
const lastLoadedIndex = ref(0);
const locationsModal = ref<InstanceType<typeof ModalComponent>>();

const loading = ref(false);

const { apm } = useApm();

const watchInputSearch = () => {
    lastRecordsCount.value = 0;
    lastLoadedIndex.value = 0;
    loadedTrips.value = [];
    accordionActive.value = -1;
    ridesStore.setLocationFocus([]);
    ridesStore.setLocationFocusTrip(undefined);
};

watch(inputSearch, watchInputSearch);

onMounted(() => {
    watchInputSearch();
});

/* get cmr detail */
function SearchLocationsCMR() {
    if (inputSearch.value) {
        watchInputSearch();
        getData();
    }
}

function focusLocation(location: ITripHistoryLocation, trip: ITripHistory) {
    ridesStore.setLocationFocusTrip(trip.tripDetail);
    ridesStore.setLocationFocus([
        {
            lat: location.lat,
            lng: location.lng,
            acc: location.acc,
            time: location.time,
            id: location.id,
            idTrip: trip.tripDetail.idTrip
        }
    ]);
    nextTick(() => {
        // $root.$emit("location.focus", location);
        locationsModal.value?.closeModal();
    });
}

function focusTrip(location: Array<ITripHistoryLocation>, tripDetail: ITripHistoryDetail) {
    ridesStore.setLocationFocusTrip(tripDetail);
    ridesStore.setLocationFocus(location);
    locationsModal.value?.closeModal();
}

/* get cmr detail */
function getData() {
    loading.value = true;
    axios
        .get<Array<ITripHistory>>("api/cmr/tripsHistory/" + inputSearch.value.toUpperCase(), {
            params: {
                tabIndex: lastLoadedIndex.value,
                maxRecords: maxRecords.value,
                lastRecordsCount: lastRecordsCount.value
            }
        })
        .then((result) => {
            lastRecordsCount.value = result.data.length;
            lastLoadedIndex.value++;
            loadedTrips.value.push(...result.data);
            if (result.data.length === 1 && result.data[0].locations.length) {
                ridesStore.setLocationFocusTrip(result.data[0].tripDetail);
                ridesStore.setLocationFocus(result.data[0].locations);
            }
            loading.value = false;
        })
        .catch((e) => {
            apm.captureError(e);
            loadedTrips.value = [];
            loading.value = false;
            lastRecordsCount.value = 0;
            lastLoadedIndex.value = 0;
        });
}

function toggleAccordion(index: number) {
    accordionActive.value = accordionActive.value === index ? -1 : index;

    setTimeout(() => document.getElementById(`tripHeading${index}`)?.scrollIntoView({ behavior: "smooth" }), 300);
}

function showModal() {
    locationsModal.value?.showModal();
}

function closeModal() {
    locationsModal.value?.closeModal();
}

defineExpose({
    showModal,
    closeModal
});
</script>
