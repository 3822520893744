<template>
    <CommonModalComponent
        id="viewOpenTimeSkipCMRModal"
        ref="viewOpenTimeSkipCMRModal"
        :title="$t('label.skiptimecmr')"
        :custom-footer="true"
    >
        <div>
            <div class="form-control-group">
                <label for="exampleInputEmail1">{{ $t("label.inputcmrnumber") }}</label>
                <input v-model="inputSearchCMR" type="test" class="form-control" Placeholder="" />
            </div>
            <div v-if="SearchNotFound">
                <p class="text-danger">{{ $t("errors.containers.5") }}</p>
            </div>
            <button :disabled="!inputSearchCMR" class="btn btn-primary btn-sm" @click="SearchSkipTimeCMR">
                {{ $t("label.search") }}
            </button>
        </div>

        <div v-if="detailCMRs.length > 0">
            <hr />
            <div class="mt-4">
                <ul v-if="detailCMRs.length > 0">
                    <li v-for="detailCMR in detailCMRs" :key="detailCMR.CMR">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <input v-model="OpenRidescheckedID" type="checkbox" :value="detailCMR.CMR" />
                                    CMR: {{ detailCMR.CMR }}
                                </div>
                                <div>IE: {{ detailCMR.IE }}</div>
                                <div class="col-sm-12">Depo: {{ detailCMR.DEPO }}</div>
                                <div class="col-sm-12">
                                    {{ $t("i18n.report.confirmation.company") }}: {{ detailCMR.DOPRAVCE }}
                                </div>
                                <div class="col-sm-12">
                                    {{ $t("i18n.report.confirmation.driver") }}: {{ detailCMR.ID_RIDICI }}
                                </div>
                                <div class="col-md-1">
                                    <font-awesome-icon v-if="detailCMR.DELIVERY_IGNORE_TIME === 1" icon="check" />
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="detailCMRs.length">
            <hr />
            {{ $t("actions.header") }}: <i>{{ $t("label.selectskiptimecmr") }}</i>
        </div>

        <template #footer>
            <button v-if="OpenRidescheckedID.length > 0" type="button" class="btn btn-primary" @click="setData">
                {{ $t("btn.save") }}
            </button>
            <button type="button" class="btn btn-secondary" @click="viewOpenTimeSkipCMRModal?.closeModal()">
                {{ $t("btn.close") }}
            </button>
        </template>
    </CommonModalComponent>
</template>

<script setup lang="ts">
import type ModalComponent from "~~/components/common/ModalComponent.vue";
const axios = useAxios();
const detailCMRs = ref<Array<any>>([]);
const SearchNotFound = ref(false);
const inputSearchCMR = ref("");
const OpenRidescheckedID = ref<Array<any>>([]);
const viewOpenTimeSkipCMRModal = ref<InstanceType<typeof ModalComponent>>();

onMounted(() => {
    nextTick(() => {
        viewOpenTimeSkipCMRModal.value?.onOpenedEvent(() => {
            init();
        });
    });
});

/* get cmr detail */
function SearchSkipTimeCMR() {
    if (inputSearchCMR.value) {
        detailCMRs.value = [];
        getData(inputSearchCMR.value.toUpperCase());
    }
}

/* get cmr detail */
function getData(inputSearchCMRAttr: string) {
    OpenRidescheckedID.value = [];
    axios.get("api/cmr/skipTimeCMR/" + inputSearchCMRAttr).then((cmrResult) => {
        const cmr = cmrResult.data[0];
        if (cmr) {
            detailCMRs.value.push(cmr);
            inputSearchCMR.value = "";
            SearchNotFound.value = false;
        } else {
            SearchNotFound.value = true;
        }
    });
}

/* set skip selected cmr   */
function setData() {
    axios
        .patch("api/cmr/skipTimeCMR", {
            OpenTripToClose: OpenRidescheckedID.value
        })
        .then(() => {
            viewOpenTimeSkipCMRModal.value?.closeModal();
        });
}

/* set clear data */
function init() {
    detailCMRs.value = [];
    SearchNotFound.value = false;
    OpenRidescheckedID.value = [];
}

function showModal() {
    viewOpenTimeSkipCMRModal.value?.showModal();
}

function closeModal() {
    viewOpenTimeSkipCMRModal.value?.closeModal();
}

defineExpose({
    showModal,
    closeModal
});
</script>
